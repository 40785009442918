// character-ai\src\components\CharacterCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CharacterCard = ({ character }) => {
    const navigate = useNavigate();
    const avatarUrl = `http://127.0.0.1:5000/${character.avatar}`;

    const handleCardClick = () => {
        navigate(`/chat/${character.role_id}`, { state: character });
    };

    return (
        <div className="bg-gray-800 rounded-lg overflow-hidden cursor-pointer" onClick={handleCardClick}>
            <div className="relative pb-[133%]">
                <img src={avatarUrl} alt={character.role_name} className="absolute top-0 left-0 w-full h-full object-cover" />
                <div className="absolute top-2 left-2 bg-pink-500 text-white text-xs px-2 py-1 rounded">Unfiltered</div>
                <div className="absolute bottom-2 right-2 text-white text-xs">
                    <span>👁️ {character.views || 0}</span>
                    <span className="ml-2">💬 {character.chats || 0}</span>
                </div>
            </div>
            <div className="p-2">
                <h3 className="text-lg font-semibold truncate">{character.role_name}</h3>
                <div className="flex flex-wrap gap-1 mt-1">
                    {(character.tags || []).map((tag, index) => (
                        <span key={index} className="bg-gray-700 text-xs px-2 py-1 rounded">{tag}</span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CharacterCard;