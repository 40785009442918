// src/services/characterService.js
import axios from 'axios';

const API_BASE_URL = 'http://localhost:5000';
const URL_GET_ALL_ROLES = `${API_BASE_URL}/get_all_roles`;
const URL_STORE_CHAT_LOG = `${API_BASE_URL}/store_chat_log`;
const URL_GET_CHAT_LOG = `${API_BASE_URL}/get_chat_log`;
const URL_GET_USER_INFO = `${API_BASE_URL}/get_user`;
const URL_GET_BOT_RESPONSE = `${API_BASE_URL}/fetch_bot_response`;
const URL_UPDATE_USER = `${API_BASE_URL}/update_user`;
const URL_LOGOUT = `${API_BASE_URL}/logout`;
const URL_CHECK_AUTH = `${API_BASE_URL}/check_auth`;
const URL_CREATE_ROLE = `${API_BASE_URL}/create_role`;
const URL_PARSE_CHARACTER_CARD = `${API_BASE_URL}/parse-character-card`;

export const getCharacters = async (page, perPage) => {
    const response = await axios.get(URL_GET_ALL_ROLES, { params: { page, per_page: perPage } });
    return response.data;
};

export const store_chat_log = async (userId, roleId, messages) => {
    const response = await axios.post(URL_STORE_CHAT_LOG, {
        user_id: userId,
        role_id: roleId,
        messages: messages
    });
    // console.log(response.data)
};

export const fetchChatLog = async (userId, roleId) => {
    try {
        const response = await axios.get(URL_GET_CHAT_LOG, { params: { user_id: userId, role_id: roleId } });
        console.log('fetchChatLog:', response.data)
        return response.data;
    } catch (error) {
        console.error('Error fetching chat log:', error);
    }
};

export const fetchUserInfo = async (identifier) => {
    try {
        const response = await axios.get(`${URL_GET_USER_INFO}?identifier=${identifier}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
    }
};

export const fetchBotResponse = async (messages) => {
    try {
        const response = await axios.post(URL_GET_BOT_RESPONSE, {
            'messages': messages
        });

        // 确保返回的数据格式正确
        if (response.data && response.data.message) {
            return response.data.message;
        } else {
            throw new Error('Invalid response format');
        }
    } catch (error) {
        console.error('Error fetching bot response:', error);
        throw error; // 确保错误被传递给调用者
    }
}

export const updateUserInfo = async (userData) => {
    try {
        const response = await axios.post(URL_UPDATE_USER, userData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error updating user info:', error);
        throw error;
    }
}

export const logoutUser = async () => {
    try {
        const response = await axios.get(URL_LOGOUT, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error logging out:', error);
        throw error;
    }
};

export const checkAuth = async () => {
    try {
        const response = await axios.get(URL_CHECK_AUTH, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error checking auth:', error);
        return { authenticated: false };
    }
};

export const createRole = async (roleData) => {
    try {
        const response = await axios.post(URL_CREATE_ROLE, roleData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error creating role:', error);
        throw error;
    }
};

export const parseCharacterCard = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(URL_PARSE_CHARACTER_CARD, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error parsing character card:', error);
        throw error;
    }
};