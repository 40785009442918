// character-ai\src\App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CharacterList from './components/CharacterList';
import ChatPage from './components/ChatPage';
import RegisterPage from './components/RegisterPage';
import CreateCharacterPage from './components/CreateCharacterPage';
import LoginPage from './components/LoginPage';
import EditProfile from './components/EditProfile'; // Imported the new UserProfile component
import './input.css';

const App = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Routes>
        <Route path="/" element={<CharacterList />} />
        <Route path="/chat/:roleId" element={<ChatPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/create-character" element={<CreateCharacterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/edit-profile" element={<EditProfile />} /> {/* Added the new profile route */}
      </Routes>
    </div>
  );
};

export default App;