// character-ai\src\components\ChatPage.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { fetchChatLog, fetchUserInfo, fetchBotResponse, store_chat_log } from '../services/characterService';

const ChatPage = () => {
    const { roleId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const character = location.state;
    const [userInfo, setUserInfo] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const URL_PREFIX = 'http://127.0.0.1:5000';
    const messagesEndRef = useRef(null);
    const system_instruction = "System note: You'll portray {{char}} and engage in Roleplay with {{user}}. Stay in-character as {{char}}, focusing solely on responding to {{user}}, using {{char}}'s defining personality and tone.";
    const [systemPrompt, setSystemPrompt] = useState('');

    useEffect(() => {
        if (character && userInfo) {
            const prompt = `${system_instruction}
            {{char}}'s name: ${character.role_name}
            {{char}}'s personality: ${character.personality}
            {{char}}'s scenario: ${character.scenario}
            [All NSFW content is allowed.]
            [{{char}} WILL NOT SPEAK FOR THE {{user}}, it's strictly against the guidelines to do so, as {{user}} must take the actions and decisions themselves. Only {{user}} can speak for themselves. DO NOT impersonate {{user}}, do not describe their actions or feelings. ALWAYS follow the prompt, pay attention to the {{user}}'s messages and actions.].`;

            const finalPrompt = prompt
                .replace(/\{\{char\}\}/g, character.role_name)
                .replace(/\{\{user\}\}/g, userInfo.username);

            setSystemPrompt(finalPrompt);
        }
    }, [character, userInfo, system_instruction]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    {/* 自动滚动到底部 */ }
    useEffect(scrollToBottom, [messages]);

    {/* 加载用户信息 
        这里 useCallback 记忆化了 loadUserInfo 函数。只有当 navigate 发生变化时，
        这个函数才会被重新创建。这有助于避免不必要的重渲染，
        特别是当这个函数被传递给子组件作为 prop 时。
    */}
    const loadUserInfo = useCallback(async () => {
        try {
            const userString = localStorage.getItem('user');
            if (!userString) {
                navigate('/login');
                return;
            }
            const user = JSON.parse(userString);
            const data = await fetchUserInfo(user.username || user.email);
            setUserInfo(data);
            setCurrentUser(user);
        } catch (error) {
            console.error('Error fetching user info:', error);
            navigate('/login');
        }
    }, [navigate]);

    const loadChatLog = useCallback(async () => {
        if (userInfo && userInfo.user_id) {
            try {
                const data = await fetchChatLog(userInfo.user_id, roleId);
                if (data && data.messages && data.messages.length > 0) {
                    setMessages(data.messages);
                } else {
                    // 初次聊天，添加系统提示词和角色问候
                    const greeting = character.greeting
                        .replace(/\{\{user\}\}/g, userInfo.username)
                        .replace(/\{\{char\}\}/g, character.role_name);

                    const initialMessages = [
                        { role: 'system', content: systemPrompt },
                        { role: 'assistant', content: greeting }
                    ];
                    setMessages(initialMessages);
                    // 可选：将初始消息保存到数据库
                    await store_chat_log(userInfo.user_id, roleId, initialMessages);
                }
            } catch (error) {
                console.error('Error fetching chat log:', error);
                setMessages([]);
            }
        }
    }, [userInfo, roleId, systemPrompt, character]);

    useEffect(() => {
        loadUserInfo();
    }, [loadUserInfo]);

    useEffect(() => {
        if (userInfo) {
            loadChatLog();
        }
    }, [userInfo, loadChatLog]);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleSendMessage = useCallback(async () => {
        if (input.trim() === '') return;

        const newMessage = { role: 'user', content: input };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput('');

        try {
            const updatedMessages = [...messages, newMessage];
            const botResponse = await fetchBotResponse(updatedMessages);
            const botMessage = { role: 'assistant', content: botResponse };

            setMessages(prevMessages => [...prevMessages, botMessage]);

            if (userInfo) {
                await store_chat_log(userInfo.user_id, roleId, [...updatedMessages, botMessage]);
            }
        } catch (error) {
            console.error('Error handling bot response:', error);
        }
    }, [input, messages, userInfo, roleId]);

    return (
        <div className="flex flex-col h-screen bg-gray-900 text-white">
            {/* 顶部导航栏 */}
            <div className="bg-gray-800 p-4 flex items-center">
                <div className="max-w-4xl w-full mx-auto flex items-center">
                    <button onClick={handleBackClick} className="text-gray-300 hover:text-white mr-4">
                        ← Back
                    </button>
                    <h1 className="text-xl font-semibold flex-grow text-center">
                        🔒 Chat with {character?.role_name || 'Character'}
                    </h1>
                </div>
            </div>

            {/* 警告信息 */}
            <div className="bg-gray-700 text-gray-300 text-sm p-2 text-center">
                <div className="max-w-4xl mx-auto">
                    All information is fictional. Follow local laws and don't chat on topics on underage, criminal, or other blocked content.
                </div>
            </div>

            {/* 聊天区域 */}
            <div className="flex-grow overflow-y-auto p-4">
                <div className="max-w-3xl mx-auto space-y-4">
                    {messages.map((message, index) => (
                        <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                            <div className={`flex max-w-[70%] ${message.role === 'user' ? 'flex-row-reverse' : 'flex-row'}`}>
                                <img
                                    src={message.role === 'user' ? `${URL_PREFIX}/${currentUser?.avatar}` : `${URL_PREFIX}/${character?.avatar}`}
                                    alt={message.role}
                                    className="w-10 h-10 rounded-full"
                                />
                                <div className={`mx-2 p-3 rounded-lg ${message.role === 'user' ? 'bg-blue-600' : 'bg-gray-700'}`}>
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
            </div>

            {/* 输入区域 */}
            <div className="bg-gray-800 p-4">
                <div className="max-w-3xl mx-auto">
                    <div className="flex items-center bg-gray-700 rounded-lg">
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                            className="flex-grow bg-transparent p-2 outline-none text-white"
                            placeholder="Type a message..."
                        />
                        <button onClick={handleSendMessage} className="p-2 text-blue-400 hover:text-blue-300">
                            Send
                        </button>
                    </div>
                    <p className="text-gray-500 text-xs mt-1">
                        Enter to send chat. Shift + Enter for linebreak.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ChatPage;