// src/components/CharacterList.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CharacterCard from './CharacterCard';
import { getCharacters } from '../services/characterService';
import { logoutUser, checkAuth } from '../services/characterService';
import logoImage from '../resources/images/logo.png';

const CharacterList = () => {
    const [characters, setCharacters] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [user, setUser] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCharacters = async () => {
            const data = await getCharacters(page, perPage);
            setCharacters(data);
        };
        fetchCharacters();
    }, [page, perPage]);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const authResult = await checkAuth();
                if (!authResult.authenticated) {
                    navigate('/login');
                } else {
                    // 可能需要更新本地存储的用户信息
                    localStorage.setItem('user', JSON.stringify(authResult.user));
                }
            } catch (error) {
                console.error('Auth check error:', error);
                navigate('/login');
            }
        };
        checkAuthentication();
    }, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Assuming you have the user's username or email stored in localStorage or context
                const userData = localStorage.getItem('user');
                // const userData = await getCurrentUser(identifier);
                if (userData) {
                    setUser(JSON.parse(userData));
                }
                else {
                    console.error('No user data found');
                }
                // console.log('userData:', userData.avatar);

                // setUser(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const handleNextPage = () => {
        setPage(page + 1);
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleHomeClick = () => {
        // Reset to page 1 or refresh character list
        setPage(1);
    };

    const handleRecentChatsClick = () => {
        // Navigate to recent chats page
        navigate('/recent-chats');
    };

    const handleCreateCharacterClick = () => {
        // Navigate to create character page
        navigate('/create-character');
    };

    const handleLogout = async () => {
        try {
            await logoutUser();
            localStorage.removeItem('user'); // 清除本地存储的用户信息
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
            // 可以在这里添加一些用户反馈，比如显示一个错误消息
        }
    };
    const handleEditProfile = () => {
        navigate('/edit-profile');
    };

    return (
        <div className="flex bg-gray-900 min-h-screen text-white">
            {/* Sidebar */}
            <div className="w-64 bg-black p-4 flex flex-col">
                <div className="mb-6 flex justify-center">
                    <img src={logoImage} alt="Logo" className="w-16 h-16" />
                </div>
                <nav className="flex-1">
                    <button onClick={handleHomeClick} className="w-full py-2 px-4 text-left text-pink-500 mb-2 hover:bg-gray-800 transition duration-300">🏠 Home</button>
                    <button onClick={handleRecentChatsClick} className="w-full py-2 px-4 text-left text-white mb-2 hover:bg-gray-800 transition duration-300">💬 Recent Chats</button>
                    <button onClick={handleCreateCharacterClick} className="w-full py-2 px-4 text-left text-white mb-2 hover:bg-gray-800 transition duration-300">🎨 Create</button>
                    <button className="w-full py-2 px-4 text-left text-white mb-2 hover:bg-gray-800 transition duration-300">👤 Profile</button>
                    <button className="w-full py-2 px-4 text-left text-white mb-2 hover:bg-gray-800 transition duration-300">💲 Pricing</button>
                    <button className="w-full py-2 px-4 text-left text-white mb-2 hover:bg-gray-800 transition duration-300">🎁 Bonus</button>
                    <button className="w-full py-2 px-4 text-left text-white mb-2 hover:bg-gray-800 transition duration-300">🔔 Notifications</button>
                </nav>
                <button className="w-full py-2 px-4 text-left text-white hover:bg-gray-800 transition duration-300">≡ More</button>
            </div>

            {/* Main content */}
            <div className="flex-1 flex flex-col">
                {/* Top bar */}
                <div className="bg-gray-800 p-4 flex justify-between items-center">
                    <div className="flex-1 max-w-3xl">
                        <input type="text" placeholder="Search" className="w-full bg-gray-700 text-white px-4 py-2 rounded-full" />
                    </div>
                    <div className="flex items-center space-x-4 ml-4">
                        <select className="bg-gray-700 text-white px-2 py-1 rounded">
                            <option>English</option>
                            <option>Chinese</option>
                            {/* Add more language options */}
                        </select>
                        <div className="relative">
                            <img
                                src={'http://localhost:5000/' + user?.avatar}
                                alt="User Avatar"
                                className="w-10 h-10 rounded-full cursor-pointer"
                                onClick={() => setShowDropdown(!showDropdown)}
                            />
                            {showDropdown && (
                                <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg">
                                    <button onClick={handleEditProfile} className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-600">
                                        Edit Profile
                                    </button>
                                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-600">
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Character grid */}
                <div className="flex-1 p-8 overflow-y-auto">
                    <div className="mb-4 flex justify-between items-center">
                        <h2 className="text-2xl font-bold">Characters</h2>
                        <div className="flex space-x-2">
                            <select className="bg-gray-700 text-white px-2 py-1 rounded">
                                <option>Recent Hits</option>
                                <option>Female</option>
                                {/* Add more sorting options */}
                            </select>
                            <select className="bg-gray-700 text-white px-2 py-1 rounded">
                                <option>Female</option>
                                {/* Add more filter options */}
                            </select>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
                        {characters.map(character => (
                            <CharacterCard key={character.role_id} character={character} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CharacterList;