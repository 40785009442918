import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createRole, parseCharacterCard } from '../services/characterService';

// 预设的标签选项
const PRESET_TAGS = [
    'Friendly', 'Mysterious', 'Funny', 'Serious', 'Adventurous',
    'Romantic', 'Intellectual', 'Creative', 'Spiritual', 'Energetic'
];

const CreateCharacterPage = () => {
    const navigate = useNavigate();
    // 初始化表单数据状态
    const [formData, setFormData] = useState({
        name: '',
        avatar: null,
        introduction: '',
        visibility: 'Public',
        rating: 'Filtered',
        tags: [],
        gender: '',
        public_definition: 'Yes',
        greeting: '',
        personality: '',
        scenario: '',
        example_conversations: [{ user: '', character: '' }],
        creator_id: '1',
        characterCard: null,
    });

    // 处理输入字段变化
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // 处理头像文件选择
    const handleAvatarChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            avatar: e.target.files[0]
        }));
    };

    // 处理标签选择
    const handleTagChange = (tag) => {
        setFormData(prevState => ({
            ...prevState,
            tags: prevState.tags.includes(tag)
                ? prevState.tags.filter(t => t !== tag)
                : [...prevState.tags, tag]
        }));
    };

    const handleCharacterCardUpload = async (e) => {
        const file = e.target.files[0];
        setFormData(prevState => ({
            ...prevState,
            characterCard: file,
            avatar: file  // 将角色卡文件同时设置为头像
        }));

        try {
            const characterInfo = await parseCharacterCard(file);
            setFormData(prevState => ({
                ...prevState,
                name: characterInfo.data.name || '',
                personality: characterInfo.data.personality || '',
                greeting: characterInfo.data.first_mes || '',
                scenario: characterInfo.data.scenario || '',
                tags: characterInfo.data.tags || [],
            }));
        } catch (error) {
            console.error("Error parsing character card:", error);
            // 可以在这里添加错误处理，比如显示一个错误消息给用户
        }
    };

    // 处理表单提交
    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitData = new FormData();

        // 将表单数据添加到 FormData 对象
        for (const key in formData) {
            if (formData[key] != null && formData[key] !== '') {
                if (key === 'example_conversations' || (Array.isArray(formData[key]) && key !== 'tags')) {
                    // 将数组或对象转换为 JSON 字符串
                    submitData.append(key, JSON.stringify(formData[key]));
                } else if (key === 'avatar' && formData[key] instanceof File) {
                    // 直接添加文件对象
                    submitData.append(key, formData[key]);
                } else if (key === 'tags' && Array.isArray(formData[key])) {
                    // 将标签数组转换为逗号分隔的字符串
                    submitData.append(key, formData[key].join(','));
                } else {
                    // 其他情况，直接添加值
                    submitData.append(key, formData[key]);
                }
            }
        }

        try {
            const response = await createRole(submitData);
            console.log('Role created successfully:', response);
            // 处理成功创建角色后的逻辑，比如导航到新创建的角色页面
            // navigate(`/chat/${response.id}`);
            navigate(`/`);
        } catch (error) {
            console.error('Error creating role:', error);
            // 处理错误，比如显示错误消息给用户
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 text-white p-4">
            <div className="max-w-3xl mx-auto">
                <h1 className="text-2xl font-bold mb-4 text-pink-500">New Character <span className="text-green-500">Draft</span></h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* 角色卡上传板块 */}
                    <div className="bg-gray-800 p-4 rounded-lg mb-6">
                        <h2 className="text-lg font-semibold text-pink-500 mb-2">Upload Character Card</h2>
                        <p className="text-sm text-gray-400 mb-4">You can upload a character card image to automatically generate character information.</p>
                        <div className="flex items-center space-x-4">
                            {formData.characterCard && (
                                <img src={URL.createObjectURL(formData.characterCard)} alt="Character Card Preview" className="w-32 h-32 object-cover rounded" />
                            )}
                            <label className="cursor-pointer bg-pink-500 text-white px-4 py-2 rounded hover:bg-pink-600 transition duration-300">
                                Upload Character Card
                                <input
                                    type="file"
                                    onChange={handleCharacterCardUpload}
                                    className="hidden"
                                    accept="image/*"
                                />
                            </label>
                        </div>
                    </div>

                    {/* 角色名称输入 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Name *</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            placeholder="Give your character a wonderful name"
                            required
                        />
                    </div>

                    {/* 角色头像上传 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Character Photo</label>
                        <div className="flex items-center space-x-4">
                            {(formData.avatar || formData.characterCard) && (
                                <img
                                    src={URL.createObjectURL(formData.avatar || formData.characterCard)}
                                    alt="Avatar Preview"
                                    className="w-32 h-32 object-cover rounded"
                                />
                            )}
                            <button
                                type="button"
                                onClick={() => document.getElementById('avatar-input').click()}
                                className="bg-pink-500 text-white px-4 py-2 rounded"
                            >
                                Change
                            </button>
                            <input
                                id="avatar-input"
                                type="file"
                                onChange={handleAvatarChange}
                                className="hidden"
                                accept="image/*"
                            />
                        </div>
                    </div>

                    {/* 角色介绍输入 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Introduction *</label>
                        <textarea
                            name="introduction"
                            value={formData.introduction}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            rows="3"
                        ></textarea>
                    </div>

                    {/* 可见性选择 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Visibility *</label>
                        <div className="space-y-2">
                            {['Public', 'Private'].map((option) => (
                                <label key={option} className="flex items-center">
                                    <input
                                        type="radio"
                                        name="visibility"
                                        value={option}
                                        checked={formData.visibility === option}
                                        onChange={handleInputChange}
                                        className="mr-2"
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* 分级选择 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Rating *</label>
                        <div className="space-x-4">
                            {['Filtered', 'Unfiltered'].map((option) => (
                                <label key={option} className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        name="rating"
                                        value={option}
                                        checked={formData.rating === option}
                                        onChange={handleInputChange}
                                        className="mr-2"
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* 标签多选 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Tags</label>
                        <div className="grid grid-cols-2 gap-2">
                            {PRESET_TAGS.map((tag) => (
                                <label key={tag} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={formData.tags.includes(tag)}
                                        onChange={() => handleTagChange(tag)}
                                        className="mr-2"
                                    />
                                    {tag}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* 性别选择 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Gender *</label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            required
                        >
                            <option value="">Choose gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    {/* 问候语输入 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Greeting *</label>
                        <textarea
                            name="greeting"
                            value={formData.greeting}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            rows="2"
                        ></textarea>
                    </div>

                    {/* 个性描述输入 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Personality</label>
                        <textarea
                            name="personality"
                            value={formData.personality}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            rows="3"
                        ></textarea>
                    </div>

                    {/* 场景描述输入 */}
                    <div>
                        <label className="block text-pink-500 mb-2">Scenario</label>
                        <textarea
                            name="scenario"
                            value={formData.scenario}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            rows="3"
                            placeholder="Describe the environment the Character is in."
                        ></textarea>
                    </div>

                    {/* 提交按钮 */}
                    <button type="submit" className="w-full bg-pink-500 text-white py-2 rounded hover:bg-pink-600 transition duration-300">
                        Create and Chat!
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateCharacterPage;