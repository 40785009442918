import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserInfo, updateUserInfo } from '../services/characterService';

const EditProfile = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        avatar: null,
        avatarPreview: null,
        bio: '',
        birthday: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadUserData = async () => {
            try {
                const userString = localStorage.getItem('user');
                if (!userString) {
                    navigate('/login');
                    return;
                }
                const user = JSON.parse(userString);
                const userData = await fetchUserInfo(user.username || user.email);
                setFormData({
                    username: userData.username,
                    email: userData.email,
                    bio: userData.bio || '',
                    birthday: formatDate(userData.birthday) || '',
                    avatar: null,
                    avatarPreview: userData.avatar ? `http://localhost:5000/${userData.avatar}` : null,
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to load user data. Please try again.');
                setLoading(false);
            }
        };

        loadUserData();
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prevState => ({
                ...prevState,
                avatar: file,
                avatarPreview: URL.createObjectURL(file)
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitData = new FormData();
        for (const key in formData) {
            if (key !== 'avatarPreview') {
                submitData.append(key, formData[key]);
            }
        }

        try {
            const response = await updateUserInfo(submitData);
            console.log('Update response:', response);
            alert('Profile updated successfully!');
            navigate('/');
        } catch (error) {
            console.error('Failed to update profile:', error);
            if (error.response) {
                console.log('Error response:', error.response.data);
                setError(`Failed to update profile: ${error.response.data.message}`);
            } else if (error.request) {
                console.log('Error request:', error.request);
                setError('Network error. Please check your connection.');
            } else {
                setError('An unexpected error occurred.');
            }
        }
    };

    if (loading) return <div className="text-white text-center">Loading...</div>;
    if (error) return <div className="text-red-500 text-center">{error}</div>;

    return (
        <div className="min-h-screen bg-gray-900 text-white p-4">
            <div className="max-w-3xl mx-auto">
                <h1 className="text-2xl font-bold mb-4 text-pink-500">Edit Profile</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-pink-500 mb-2">Username</label>
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-pink-500 mb-2">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-pink-500 mb-2">Profile Photo</label>
                        <div className="flex items-center space-x-4">
                            {formData.avatarPreview && (
                                <img
                                    src={formData.avatarPreview}
                                    alt="Avatar Preview"
                                    className="w-32 h-32 object-cover rounded"
                                />
                            )}
                            <button
                                type="button"
                                onClick={() => document.getElementById('avatar-input').click()}
                                className="bg-pink-500 text-white px-4 py-2 rounded"
                            >
                                Change
                            </button>
                            <input
                                id="avatar-input"
                                type="file"
                                onChange={handleAvatarChange}
                                className="hidden"
                                accept="image/*"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-pink-500 mb-2">Bio</label>
                        <textarea
                            name="bio"
                            value={formData.bio}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                            rows="3"
                        ></textarea>
                    </div>

                    <div>
                        <label className="block text-pink-500 mb-2">Birthday</label>
                        <input
                            type="date"
                            name="birthday"
                            value={formData.birthday}
                            onChange={handleInputChange}
                            className="w-full bg-gray-800 rounded p-2 text-white"
                        />
                    </div>

                    <button type="submit" className="w-full bg-pink-500 text-white py-2 rounded hover:bg-pink-600 transition duration-300">
                        Update Profile
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditProfile;